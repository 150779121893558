<template>
  <div>
    <div class="locale">
      <h2>机构答卷</h2>
        <ol>
            <li><router-link to="/">后台</router-link></li>
            <li><a href="javascript:;">测验</a></li>
            <li><a href="javascript:;">答卷</a></li>
            <li>查询</li>
        </ol>
    </div>

    <div class="filter">
      <a-form-model layout="inline" @submit="filting" @submit.native.prevent>
        <a-form-model-item>
          <a-select v-model="filter.role_id" :options="option.roles" />
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="filter._word" placeholder="可输入关键字" />
        </a-form-model-item>
        <a-form-model-item>
          <a-button html-type="submit">搜索</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <div class="box">
<!--      <div class="box-head">-->
<!--        <h3>登录列表</h3>-->
<!--      </div>-->
      <div class="box-body">
          <a-empty v-if="items.length == 0" />
          <table class="list" v-if="items.length > 0">
          <tr>
            <th style="width: 120px;">#</th>
            <th>用户</th>
            <th>角色</th>
            <th>答题数</th>
            <th>单题分值</th>
            <th>得分</th>
            <th style="width: 120px;">状态</th>
            <th style="width: 120px;">时间</th>
            <th style="width: 80px;">操作</th>
          </tr>
          <tr v-for="item in items">
            <td>{{item.id}}</td>
            <td>
              <div>{{item.role.name}}</div>
              <small>{{item.role.id}}</small>
            </td>
            <td>
              <div>{{item.user.nickname}}</div>
              <small>{{item.user.mobile}}</small>
            </td>
            <td>
              <div>{{item.count.answers}}</div>
            </td>
            <td>
              <div>{{item.value}}</div>
            </td>
            <td>
              <div>{{item.score}}</div>
            </td>
            <td>
              <label class="label" :class="[item.state.value]">{{item.state.label}}</label>
            </td>
            <td>
              <div>{{item.created.format('yyyy-MM-dd hh:mm:ss')}}</div>
            </td>
            <td>
                <a-button-group size="small">
                    <a-tooltip placement="top" title="详情">
                        <router-link class="ant-btn" :to="'/test/paper/detail/'+item.id"><a-icon type="profile" /></router-link>
                    </a-tooltip>
                </a-button-group>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="pager">
      <a-pagination @change="paging" :page-size="paged.size" :default-current="paged.page" :total="paged.count" show-quick-jumper/>
    </div>

  </div>
</template>


<script>
  export default {
    name: 'OrganLoginLocal',
    data() {
      return {
        first: true,
        loading: false,
        option: {
          roles: [ { label: '- 所属角色 -', value: ''} ],
        },
        filter: {
          role_id: ''
        },
        paged: {
          offset: 0, limit: 10, count: 0, size: 10, page: 0
        },
        items: [],
        last: 1,

        batched: false,
      }
    },
      mounted() {
      this.request();
    },

    methods: {
      request(page) {
        let filter = {};
        for (let k in this.filter) {
          let o = this.filter[k];
          if (o && o['value'] !== undefined) {
            filter[k] = o.value;
          } else {
            filter[k] = o;
          }
        }

        filter._size = this.paged.size || 10;
        filter._index = page || 1;

        this.$get('/test/paper/filter', filter).then(res => {
          let ajax = res.data;
          if (ajax.data) {
            this.$util.imageField(ajax.data.items);
            this.$util.timeFields(ajax.data.items);
          
            this.items = ajax.data.items;
            this.paged = ajax.data.paged;

            if (this.first) {
              for (let k in this.option) {
                if (ajax.data.option[k]) {
                  this.option[k].push(...ajax.data.option[k])
                }
              }
            }
            
          }

          this.first = false;
          this.last = page;
        })
      },
      paging(page) {
        this.request(page);
      },
      filting() {
        this.request(1);
      },


      batch() {
        this.items.forEach((item)=>{
          item.batched = !this.batched;
        });
      }
    }
  }
</script>

<style scoped lang="less">
</style>